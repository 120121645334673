import { Link } from "react-router-dom";
import styles from "../index.module.scss";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  BASIC_BLOCKED: "You are blocked by this user or you blocked this user.",

  BASIC_STEP_1: "Steps to use Berenvriendjes",
  BASIC_STEP_2:
    "Register as an employee person and create a profile.",
  BASIC_STEP_3:
    "Register as an employee and discover immediately available services that match your expertise and preferences. We offera wide range of options so you can find the perfect job.",
  BASIC_STEP_4:
    "View all services in your location and sign up for the perfect job.",
  BASIC_STEP_5:
    "With our platform you have the opportunity to view all services in your immediate vicinity. Whether you are looking for a temporary job, a flexible assignment or a long-term work assignment, whether it concerns childcare, the healthcare sector or other branches, there is always a suitable work assignment for you.",
  BASIC_STEP_6: "Yes a match! Congratulations on your first work order",
  BASIC_STEP_7:
    "Once you have found a suitable match, you are ready to get started right away. This is an exciting moment and we congratulate you on reaching this milestone.We are confident that you will successfully complete your work assignment. Your commitment and skills will contribute to the success of the project and to the satisfaction of our client.",

  BASIC_STEP_1_Q:
    "Register as an employee or self-employed person and create a profile.",
  TEXT_ARE_YoU_SURE_DELETE_THIS_MESSAGE:
    "Deleting job will not affect ongoing job contract. If you want to cancel or complete job contract, please do it from Hired worker screen.",
  BASIC_UNMUTE_NOTIFICATION_CONFIRMATION:
    "Are you sure you want to unmute messages notification?",
  BASIC_STEP_1_A: (
    <>
      Register as an employee or self-employed person and discover immediately
      available positions services that match your expertise and preferences. We
      offer a wide range of options so you can get the perfect job assignment
      find.
      <br />
      <br />
      Tip: download the app to get a service anywhere!
    </>
  ),
  BASIC_STEP_2_Q:
    "View all services in your location and register for the perfect work assignment.",
  BASIC_STEP_2_A:
    "With our platform you have the opportunity to view all services in your immediate area. Whether you are looking for a temporary job, a flexible assignment or a long-term work assignment, whether it concerns childcare, the healthcare sector or other industries, there is always a suitable work assignment for you.",
  BASIC_STEP_3_Q: "Yes a match! Congratulations on your first work assignment",
  BASIC_STEP_3_A: (
    <>
      Once you have found a suitable match, you are ready to get started right
      away to get going. This is an exciting moment and we congratulate you on
      it achieving this milestone.
      <br />
      We are confident that you will be successful in your work assignment to
      accomplish. Your commitment and skills will contribute to success of the
      project and to the satisfaction of our client.
      <br />
      <br />
      Trust is the basis of everything we do! You are at Berenvriendjes you've
      come to the right place <br />
      For enthusiastic and well-instructed employees. <br />
      Employees who know how to tackle things in their own industry.
      <br />
      <br />
      How do you hire staff?
      <ol>
        <li>Register for free and create a profile</li>
        <li>Create a repeating, urgent or normal work order</li>
        <li>Choose your employee you want to hire.</li>
        <li>Yes a Match. Your work assignment has been completed.</li>
      </ol>
    </>
  ),

  BASIC_URGENT_WARNING:
    "Job is accepted immediately by an employee without acceptance from the organization.",
  BASIC_SOON_WARNING:
    "Job is placed and must also be accepted by the employee and organization.",

  TEXT_CHANGE_DECIDED_RATE:
    "After changing the rate, the previous rate will be removed and the new rate will be applied to the future shifts.",
  TEXT_REPEAT_CONTRACT:
    "Select date to repeat the contract or you can make it repeating contract.",
  TEXT_HOME_HERO_DESCRIPTION: (
    <>
      Register for free as an employee and start looking for a
      work assignment right away! <br />
      Register for free as an Organization and immediately find the perfect
      employee for your work assignment!
    </>
  ),
  TEXT_NEW_HOME_HERO_DESCRIPTION: (
    <>
      Bij Berenvriendjes bieden wij vacatures voor een kinderopvang in Utrecht
      en omstreken voor jou aan. Als jij een passie hebt voor het werken met
      kinderen en een positieve impact wilt maken op hun groei en welzijn, dan
      hebben wij interessante vacatures voor jou beschikbaar. Bij ons kun je
      rekenen op werkomgevingen waar persoonlijke ontwikkeling en plezier
      centraal staan.
    </>
  ),
  TITLE_CONTACT_US_NEW_HOME: (
    <>
      Heb je een interessante vacature gezien bij een kinderdagverblijf op ons platform en ben je enthousiast om te solliciteren? We staan klaar om jouw vragen over de vacature van het kinderdagverblijf te beantwoorden en je te helpen bij de volgende stap in je carrière als freelancer. Werken bij een kinderopvang biedt jou niet alleen een leuke en uitdagende baan, maar ook de kans om bij te dragen aan de ontwikkeling van de jongste generatie, dus neem contact met ons op.
    </>
  ),
  TITLE_CHILD_CARE_BODY:
    "Working in a childcare center in the Utrecht region means that you are surrounded by the cheerful faces of children every day. Our vacancies for a daycare center near Utrecht offer various positions, from pedagogical employee to group leader. Every vacancy for a daycare center in the Domstad offers unique benefits, such as the opportunity to use creativity during activities and the satisfaction that comes from seeing children develop under your guidance. Moreover, as a self-employed person you work in a close-knit team where cooperation and collegiality are of paramount importance With our vacancies to work in a childcare facility, you not only have a job, but also a place that you enjoy going to every day.",
  TITLE_CHILD_CARE_BODY_TEXT:
    "Immediately find the perfect employee for your service",
  TEXT_WITH_US_YOU_HAVE_THE_FREEDOME_MSG:
    "With us you have the freedom to determine where and when you work, both as an employee. We offer options for both. You have the freedom to choose which work mode best suits your needs and preferences.",
  TEXT_REGISTER_AS_AN_EMPLOYEE_OR_FREELANCER:
    "Register as an employee and discover immediately available services that match your expertise and preferences. We offer a wide range of options so you can find the perfect job.",
  TEXT_WITH_OUR_PLATFORM_YOU_HAVE_THE_OPPORTUNITY:
    "With our platform you have the opportunity to view all services in your immediate vicinity. Whether you are looking for a temporary job, a flexible assignment or a long-term work assignment, whether it concerns childcare, the healthcare sector or other branches, there is always a suitable work assignment for you.",
  TEXT_ONCE_YOU_HAVE_FOUND_A_SUITABLE_MATCH_MSG:
    "Once you have found a suitable match, you are ready to get started right away. This is an exciting moment and we congratulate you on reaching this milestone.We are confident that you will successfully complete your work assignment. Your commitment and skills will contribute to the success of the project and to the satisfaction of our client.",
  TEXT_REGISTER_FOR_FREE_CREATE_PROFILE_MSG:
    "Register for free and create a profile",
  TEXT_CREATE_A_REPEATING_URGENT_OR_NORMAL_MSG:
    "Create a repeating, urgent or normal work order",
  TEXT_CHOOSE_YOUR_EMPLOYEE_OR_FREELANCER_MSG:
    "Choose your employee you want to hire.",
  TEXT_YES_A_MATCH_YOUR_WORK_ASSIGNEMNT_COMPLETED:
    "Yes a Match. Your work assignment has been completed.",
  TEXT_RATING: "rating",
  TEXT_AVERAGE_RATING: "average rating",
  TEXT_TOTAL_REVIEWS: "Totla Reviews",
  TEXT_EMPLOYEE_FREEDOM_MSG:
    "Empower your workforce by granting autonomy, enhancing job satisfaction, and boosting productivity. Explore the advantages of promoting ‘Employee Freedom’ in your organization.",
  TEXT_FREELANCER_FREEDOM_MSG:
    "Embrace the independence and flexibility of freelancing. Take control of your work, time, and life as you pursue your passions and projects on your terms.",
  TEXT_EMPLOYEE_FLEXIBILITY_MSG:
    "In a fast-paced world, flexibility is your competitive edge. Embrace change, pivot with ease, and seize new opportunities as they arise. Discover the strength of adaptability.",
  TEXT_FREELANCER_FLEXIBILITY_MSG:
    "Experience the freedom of freelance life. With the flexibility to choose your projects, set your own schedule, and work from anywhere, you’re in control of your professional journey. Discover the ultimate work-life balance",
  TEXT_NO_LONG_TERM_COMMITMENT_MSG:
    "The 'no strings attached' idea at Bearfriends means there are no long-term commitments for employees. In traditional employment agencies, employees can often be assigned to longer contracts with specific employers. At Berenvriendjes you determine where you put the emphasis for shorter jobs or long projects, so that you can experience more variation in your work.",
  TEXT_FOCUS_ON_SPECIFIC_SECTOR_MSG:
    "Achieve excellence by honing your expertise in chosen sectors. Specialization empowers you to provide tailored solutions, deepen client relationships, and stand out in competitive markets. Explore the benefits of industry focus and elevate your business to new heights.",
  TEXT_REGULATORY_EXPERTISE_MSG:
    "Navigating the complexities of regulations with precision. Gain a competitive edge through in-depth industry knowledge and compliance mastery.",
  TEXT_ATTENTION_TO_QUALITY_MSG:
    "Berenvriendjes focuses strongly on selecting qualified personnel with the right skills and experience for the specific needs of the childcare sector, care sector and other industries. This can lead to a higher quality of placed employees and better service to customers.",
  TEXT_ABOUT_US_DESCRIPTION_MSG:
    "Trust is the foundation of everything we do. This also applies to our employees and partners.We like to offer continuity and work on our relationships.",
  TEXT_CONTACT_US_DESCRIPTION_MSG:
    "Apply or do you have any questions? Please contact us via the options below!",
  TEXT_AVAILABLITY_MSG:
    "We are available from Monday to Friday during these hours:",
  TEXT_MONDAY: "Monday",
  TEXT_TUESDAY: "Tuesday",
  TEXT_WEDNESDAY: "Wednesday",
  TEXT_THURSDAY: "Thursday",
  TEXT_FRIDAY: "Friday",
  TEXT_WE_CAN_BE_REACHED_VIA_WHATSAPP_MSG:
    "* We can be reached via whatsapp during after hours and the weekend.",
  TEXT_ADDRESS: "Address",
  TEXT_NO_MESSAGE: "Empty Chats",
  TEXT_NO_CHAT_TITLE: "Currently, You have no chat to view.",
  TEXT_NO_TASK_TITLE: "Currently, You have no task to view.",
  TEXT_NO_CHAT_MESSAGES: (
    <>
      When you start communication on job,
      <br /> your message will be show here.
    </>
  ),
  TEXT_NO_TASKS: (
    <>
      When you start a job,
      <br /> your project tasks will be here.
    </>
  ),

  TEXT_EXPLORE_JOBS: "Explore Jobs",
  TEXT_EXPLORE_TALENT: "Explore Talent",
  TEXT_TELEPHONE: "Telephone",
  TEXT_EMAIL: "Email",
  TEXT_WHATSAPP: "Whatsapp",
  TEXT_HOURLY_WAGE: "Hourly Wage",
  TEXT_JOB_TITLE: "Job Title",
  TEXT_JOB_OFFER_CONFIRMATION: ({
    jobTitle,
    companyName,
  }: {
    jobTitle?: string;
    companyName?: string;
  }) => (
    <>
      Are you sure you want to accept the job offer for{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span>? This action is
      irreversible, and you will be officially joining the{" "}
      <span className={styles["jobTitle"]}>{companyName}</span> team.
      <br /> Click 'Confirm' to accept, or 'Cancel' to go back and reconsider.
    </>
  ),
  TEXT_JOB_OFFER_REJECTION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Are you sure you want to reject the job offer for{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span>? This action cannot
      be undone, and the offer will be declined.
      <br /> Click 'Confirm' to reject, or 'Cancel' to go back and reconsider.
    </>
  ),
  TEXT_JOB_OFFER_CANCELATION: ({ jobTitle }: { jobTitle?: string }) => (
    <>
      Are you sure you want to cancel the job invitation for{" "}
      <span className={styles["jobTitle"]}>{jobTitle}</span>? This action will
      notify the invited person, and the invitation will be withdrawn.
      <br /> Click 'Confirm' to cancel, or 'Cancel' to go back and reconsider.
    </>
  ),

  TEXT_STAFF_MSG:
    "For our employees we look at your individual needs, what suits you best? In this way we invest in your personal growth and together we look at your work-life balance. We constantly evaluate and look with you at how we can improve our quality. Berenvriendjes works towards a long-term employment relationship.",
  TEXT_PARTNERS_MSG:
    "For our partners we are not a general recruitment and selection agency, temporary employment or secondment agency. That has its advantages. Due to our specialization in pedagogy and in other branches such as education, care, cleaning, retail and industry, we have a lot of know-how, experience and insight. This also means that our employees have the correct papers so that you do not have to worry.",
  TEXT_DO_NOT_HAVE_AN_ACCOUNT: "Do not have an account?",
  TEXT_REMEMBER_ME: "Remember me",
  TEXT_OR: "OR",
  TEXT_ONCE_YOUR_PROPOSAL_HAS_COUGHT_MSG:
    "Once your proposal has caught a client's attention, they may reach out to you through our messaging system.",
  TEXT_WELCOME_MSG:
    "We're thrilled to have you on board and thank you for choosing our platform to embark on your freelancing journey. Let's dive in!",
  TEXT_CREATE_YOUR_ACCOUNT_LIST:
    "Create Your Account: To begin, head over to our website and click on the 'Sign Up' button.",
  TEXT_SETUP_YOUR_PROFILE_LIST:
    "Set Up Your Profile: Once you've successfully created your account, it's time to set up your profile.",
  TEXT_EXPLORE_PROJECT_LIST:
    "Explore Project Listings: Our web app offers a vast range of projects across various categories. Browse through the project listings to find opportunities that match your skills and interests.",
  TEXT_SUBMIT_PROPOSALS_LIST:
    "Submit Proposals: When you come across a project that aligns with your expertise, craft a compelling proposal that showcases your abilities and explains how you can add value to the client's project.",
  TEXT_COMMUNICATE_WITH_CLIENTS_LIST:
    "Communicate with Clients: Once your proposal has caught a client's attention, they may reach out to you through our messaging system.",
  TEXT_SECURE_PAYMENT_SETUP_LIST:
    "Secure Payment Setup: To ensure a seamless payment process, set up your preferred payment method securely within your account settings. ",
  TEXT_WORK_ON_PROJECTS_LIST:
    "Work on Projects: Once you've secured a project and agreed on the terms with the client, it's time to get to work! ",
  TEXT_RECEIVE_FEEDBACK_AND_RATINGS_LIST:
    "Receive Feedback and Ratings: Client feedback and ratings play a crucial role in establishing your reputation as a freelancer.",
  TEXT_EXPLORE_ADDITIONAL_FEATURES_LIST:
    "Explore Additional Features: Beyond project listings, our web app offers additional features to enhance your freelancing experience. ",
  TEXT_STAY_ACTIVE_AND_ENGAGED_LIST:
    "Stay Active and Engaged: Consistency and proactivity are key to thriving in the freelancing world. ",
  TEXT_WE_ARE_THRILLED_TO_HAVE_YOU_ON_BOARD:
    "We're thrilled to have you on board and thank you for choosing our platform to embark on your freelancing journey. Let's dive in!",
  TEXT_PLEASE_ENTER_YOUR_DETAILS:
    "Please enter your details to setup new account.",
  TEXT_STEP: "Step",
  TEXT_OF: "of",
  TEXT_I_ACCEPT_THE_TERMS_AND_CONDITIONS: "I accept the terms and conditions.",
  TEXT_CHILD_DAY_CARE: "Child Day Care",
  TEXT_HEALTH_CARE: "Health Care",

  TEXT_OFFICES: "Offices",
  TEXT_LOGISTICS: "Logistics",
  TEXT_SALES: "Sales",
  TEXT_CALL_CENTER: "Call Center",
  TEXT_EDUCATION: "Education",
  TEXT_OTHERS: "Others",
  TEXT_IT: "IT",
  TEXT_CONSTRUCTION: "Construction",
  TEXT_HOSPITALITY: "Hospitality",
  TEXT_CLEANING: "Cleaning",

  TEXT_ID_MANDATORY: "ID (Mandatory)",
  TEXT_CERTIFICATES_OPTIONAL: "Certificate (Optional)",
  TEXT_DIPLOMA_OPTIONAL: "Diploma (Optional)",
  TEXT_INSURANCE_BLOCK_OPTIONAL: "Insurance block (Optional)",
  TEXT_SCAN_BY_CAMERA: "Scan by Camera",
  TEXT_THIS_ONBOARDING_GUILD_WILL_HELP_MSG:
    "This onboarding guide will help you get started and make the most of our web app.",
  TEXT_TERMS_AND_CONDITIONS: "You must agree to the terms and conditions",
  TEXT_PASSWORD_HINT:
    "The password must include at least one lowercase letter, one uppercase letter, one digit, one special character (e.g., !@#$%^&()-__+), and be a minimum of 8 characters long.",
  TEXT_PROVIDE_PERSNAL_DETAIL: "Provide personal information.",
  TEXT_PROVIDE_BUSINESS_DETAILS: "Provide business information.",
  TEXT_PROVIDE_BANK_DETAIL: "Provide bank information.",
  TEXT_SELECT_MAXIMUM_3_BRANCHES: "Select maximum 3 branches of your business.",
  TEXT_PROVIDE_DOCUMENTATION:
    "Provide documentation and front and back of your ID is mandatory.Size of files should be less than 2MB for each.",
  TEXT_PROVIDE_CONTACT_INFORMATION: "Provide contact information.",

  TEXT_LOCATION_TIP: "*person can only standard jobs nearby 30 km not more",

  TEXT_ADRESS_DETAIL: "Provide address information.",

  TEXT_DIDNT_RECEIVE: "Didn’t receive? ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK:
    "We have sent a verification link to this email ",
  TEXT_WE_HAVE_SENT_RESET_PASSWORD_LINK:
    "We have sent a forgot link to this email ",
  TEXT_WE_HAVE_SENT_VERIFICATION_LINK_after:
    ", check your inbox and verify email to log in to your account.",
  TEXT_MARK_ALL_AS_READ: "Mark all as read",
  TEXT_SEE_ALL_NOTIFICATIONS: "See all notifications",
  TEXT_INCOMPLETE: "Incomplete",
  TEXT_POSTED_ON: "Posted On:",
  TEXT_PERSON: "Person",
  TEXT_AVATAR_REQUIREMENT: "Min 500 * 500px, .png or .jpeg",
  TEXT_HAVE_QUESTIONS_WE_ARE_HERE_TO_HELP:
    "Have questions? We are here to help",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT:
    "In the Availability Schedule, You can add his/her availability schedule. Please select the relevant date and set your availability that will show to the clients. Also you can add the task schedule here as well.",
  TEXT_INVOICE_TAGLINE:
    "Effortlessly generate invoices for completed tasks approved by organization and executed by workers.",
  TEXT_AVAILABILITY_SCHUDLE_SUB_TEXT_MOBILE:
    "In the Availability Schedule, You can add his/her availability schedule.",
  TEXT_STAY_ORGANIZED_ADD_YOUR_SCHEDULE:
    "Stay organized – add your schedule to keep track of important dates.",
  TEXT_DECLINE_JOB_NOTE:
    "Declining a job request will remove it from your shift of this jobs.",
  TEXT_REJECT_JOB_DONE_BY_WORKER:
    "Rejecting a job done by worker mean Worker has not completed the job, it will be removed from worker shift of this jobs.",
  TEXT_HANDLE_JOB_REQUEST: "Handle Job Request",
  TEXT_STAY_ORGANIZED_EDIT_YOUR_SCHEDULE:
    "Stay organized – Edit your schedule to keep track of important dates.",
  TEXT_HOURS_WORKED: "hours worked",
  TEXT_HOURS_AGO: "hours ago",
  TEXT_SEE_MORE: "See More...",
  TEXT_CANCEL_JOB: "Cancel Job",
  TEXT_DELETE_JOB: "Delete Job",
  TEXT_REPOST_JOB: "Repost Job",
  TEXT_NOTHING_HERE_YET: "Post a job to get started!",
  TEXT_FIND_JOB: "Find New Job!",
  TEXT_ASK_ORGANIZATION_TO_GENRATE_INVOICE:
    "Ask organization to genrate invoice",
  TEXT_SEE_ALL_APPLICATIONS: "See All Applications",

  TEXT_LAST_SEEN: "Last seen",
  TEXT_LAST_SEEN_JUST_NOW: "Last Seen Just Now",
  TEXT_MINUTE: "minute",
  TEXT_HOUR: "hour",
  TEXT_HOURS: "hours",
  TEXT_DAY: "day",
  TEXT_DAYS: "days",
  TEXT_AGO: "ago",
  TEXT_ONLINE: "Online",
  TEXT_TYPING: "Typing",
  TEXT_MESSAGES_LOADING: "Messages loading...",
  BASIC_HIRE_SOMEONE: "Hire someone for this branch",
  TEXT_STAY_ORGANIZED_MSG: "Stay organized msg",

  TEXT_HIRE_SOMEONE: "Hire someone!",

  TEXT_NO_JOBS: "Please try with different filters or check back later.",

  BASIC_BLOCK_CONFIRMATION: "Are you sure you want to block this user?",

  BASIC_MUTE_NOTIFICATION_CONFIRMATION:
    "Are you sure you want to mute messages notification?",

  BASIC_REPORT_CONFIRMATION: "Are you sure you want to report this user?",

  TEXT_SELECT_WORKER: "Select worker to check shifts of worker.",

  TEXT_COOKIES_MODAL: `
      Our website uses cookies to improve your experience. By using our website,
      you agree to our cookie policy. For more information, please read our
      `,
  TEXT_SELECT_WORKER_MESSAGE: (
    <>
      Select worker to check shifts of worker.,
      <br /> Change filter to see different shifts.
    </>
  ),
  TEXT_WORKER_TASKS_EMPTY: "Currently, worker has no tasks.",

  TEXT_SELECT_WORKER_MESSAGE_EMPTY: (
    <>
      Worker have tasks.,
      <br /> you can try different dates filter
    </>
  ),

  BASIC_UNBLOCK_CONFIRMATION: "Are you sure you want to unblock this user?",

  TEXT_ID_BACK_MANDATORY: "ID Back(Mandatory)",
  BASIC_TODAY: "Today",

  BASIC_EMPTY_NOTIFICATION: "Meldingsvak is leeg.",
  TEXT_ATTENTION_TO_ORGANIZATION_MSG:
    "Berenvriendjes can take a personal approach and offer tailor-made solutions that meet the specific requirements of their customers in the childcare sector. This can lead to greater satisfaction for both employers and employees.",

  TEXT_WORKING_IN_CHILD_CARE: (
    <>
      Welcome to Berenvriendjes, where passion for the well-being and
      development of children goes hand in hand with finding the perfect match
      between talented people professionals and inspiring childcare centers.
      Understanding Bear Friends we believe that the right staff makes a
      difference in a child's growth, and That's why we are looking for
      enthusiastic individuals to make us dynamic team of sports coaches,
      pedagogical staff and teaching assistants to strengthen.
      <br />
      <br />
      As a temporary employment platform, we pride ourselves on bringing
      together qualified people professionals with child care centers who strive
      for excellence in their services. Whether you are an experienced sports
      coach who loves it encouraging a healthy lifestyle, an empathetic pedagogy
      employee who is driven to create a safe and stimulating environment
      create, or a teaching assistant who is committed to individual growth, at
      Bear Friends is there a place for you.
      <br />
      <br />
      We believe in the importance of personal and professional attention
      development. That's why we strive to not only meet the needs of childcare
      centers, but also the career goals of our support employees. Together we
      build a community in which both children and professionals can thrive and
      grow.
      <br />
      <br />
      Do you want to be part of our dedicated team and have a positive impact
      on... children's lives? Then join Berenvriendjes and discover the exciting
      possibilities waiting for you. Together we can find our way paving the way
      for flourishing professionals to guide them.
    </>
  ),
  TEXT_WORKING_IN_CHILD_CARE_ORG: (
    <div>
      <style>
        {`
        body { line-height:108%; font-family:Calibri; font-size:11pt }
        h3, p { margin:0pt 0pt 8pt }
        li { margin-top:0pt; margin-bottom:8pt }
        h3 { margin-top:2pt; margin-bottom:0pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-family:'Calibri Light'; font-size:12pt; font-weight:normal; color:#1f4d78 }
        .ListParagraph { margin-left:36pt; margin-bottom:8pt; line-height:108%; font-size:11pt }
        .Kop3Char { font-family:'Calibri Light'; font-size:12pt; color:#1f4d78 }
      `}
      </style>
      <p>
        Berenvriendjes focuses entirely on compliance with laws and regulations,
        as monitored by the GGD. This means that the Certificate of Good Conduct
        and diplomas are manually checked by our HR department to ensure
        compliance with all regulations.
      </p>
      <p>
        Our process differs from other platforms because we send the connection
        requests from our HR department for the temporary workers deployed at
        your childcare organization.
      </p>
      <p>
        This guarantees that the process remains under control at
        Berenvriendjes, unlike other platforms where the responsibility lies
        with the self-employed person and the organization, which often leads to
        disputes.
      </p>
      <p>
        <strong>This makes Bear Friends UNIQUE!</strong>
      </p>
      <ol style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li style={{ marginLeft: "31.35pt", paddingLeft: "4.65pt" }}>
          <strong>Fixed prices</strong>
          <ul
            style={{
              marginRight: "0pt",
              marginLeft: "0pt",
              paddingLeft: "0pt",
              listStyleType: "disc",
            }}
          >
            <li
              style={{
                marginLeft: "27.6pt",
                lineHeight: "108%",
                paddingLeft: "8.4pt",
                fontFamily: "serif",
                fontSize: "10pt",
              }}
            >
              <span
                style={{
                  lineHeight: "108%",
                  fontFamily: "Calibri",
                  fontSize: "11pt",
                }}
              >
                Unlike other platforms, we do not charge an hourly fee from an
                organization, but we charge a fixed rate without additional
                costs.
              </span>
            </li>
          </ul>
        </li>
        <li style={{ marginLeft: "31.35pt", paddingLeft: "4.65pt" }}>
          <strong>Extensive service</strong>
        </li>
      </ol>
      <p>We offer the standard service and support of an employment agency.</p>
      <p>
        <strong>Personal planners</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            You determine the request together with our planners. You tell us
            how many employees you need and which diplomas/certificates they
            must have.
          </span>
        </li>
      </ul>
      <p>
        <strong>Flexible application options</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            Requests for services are scheduled within 24 hours of request.
            Requests can be submitted via our web app, WhatsApp, by telephone
            and by e-mail. We try to schedule the service as quickly as
            possible, depending on the means of communication that the
            organization finds most convenient.
          </span>
        </li>
      </ul>
      <p>
        <strong>24/7 Accessibility</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            For emergencies we are available 24/7 via WhatsApp!
          </span>
        </li>
      </ul>
      <p>
        <strong>Create services yourself via our platform</strong>
      </p>
      <ul style={{ margin: "0pt", paddingLeft: "0pt" }}>
        <li
          style={{
            marginLeft: "63.6pt",
            lineHeight: "108%",
            paddingLeft: "8.4pt",
            fontFamily: "serif",
            fontSize: "10pt",
          }}
        >
          <span
            style={{
              lineHeight: "108%",
              fontFamily: "Calibri",
              fontSize: "11pt",
            }}
          >
            If you do not want to use the service, you can still make your
            service live via our platform, just like on other platforms, so that
            Berenvriendjes employees can fill in the shift.
          </span>
        </li>
      </ul>
      <p style={{ marginLeft: "72pt" }}>&#xa0;</p>
      <p>
        Berenvriendjes offers a reliable, efficient and user-friendly
        alternative to other similar platforms, with a strong focus on
        regulatory compliance and extensive support for childcare organizations.
      </p>
      <p>
        If you would like to receive more information about the method and
        process, do not hesitate to contact us.
      </p>
    </div>
  ),




  TEXT_PAGE_01_DESCRIPTION1: (
    <>
      Bij Berenvriendjes bieden wij vacatures aan voor in de kinderopvang in Utrecht en omstreken. Als jij een passie hebt voor het werken met kinderen en een positieve impact wilt maken op hun groei en welzijn, dan hebben wij interessante vacatures voor jou beschikbaar. Bij ons kun je rekenen op werkomgevingen waar persoonlijke ontwikkeling en plezier centraal staan.
    </>
  ),
  TEXT_PAGE_01_DESCRIPTION2: (
    <>
      Bij Berenvriendjes bieden wij vacatures aan voor in de kinderopvang in Utrecht en omstreken. Als jij een passie hebt voor het werken met kinderen en een positieve impact wilt maken op hun groei en welzijn, dan hebben wij interessante vacatures voor jou beschikbaar. Bij ons kun je rekenen op werkomgevingen waar persoonlijke ontwikkeling en plezier centraal staan. Voor meer informatie, bezoek onze pagina over <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'#9FB5FF', fontSize:30, fontWeight:700}}>zzp’er in de kinderopvang</a>, vind je bij ons de ideale vacature.
    </>
  ),
  TEXT_PAGE_01_DESCRIPTION3: (
    <>
      Heb je een interessante vacature gezien bij een kinderdagverblijf op <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a> en ben je enthousiast om te solliciteren? We staan klaar om jouw vragen over de vacature van het kinderdagverblijf te beantwoorden en je te helpen bij de volgende stap in je carrière in loondienst of als <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a>. Werken bij een kinderopvang biedt jou niet alleen een leuke, flexibele en uitdagende baan, maar ook de kans om bij te dragen aan de ontwikkeling van de jongste generatie, dus neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op. 
    </>
  ),



  
  TEXT_PAGE_02_DESCRIPTION1: (
    <>
      Wil je freelancer bij een kinderopvang in de regio van Utrecht worden? Bij Berenvriendjes kunnen we je helpen om deze droom werkelijkheid te maken. Als freelancer bij een kinderopvang heb je de vrijheid om je eigen uren te bepalen en te werken in een omgeving waar jouw passie voor kinderen volledig tot zijn recht komt. Bij ons krijg je de mogelijkheid om je talenten en vaardigheden te benutten in een warme en stimulerende omgeving. 
    </>
  ),
  TEXT_PAGE_02_DESCRIPTION2: (
    <>
     Als freelancer bij een kinderopvang in de regio van Utrecht geniet je van talloze voordelen. Ten eerste biedt freelancen je de flexibiliteit om je eigen werktijden te bepalen. Dit betekent dat je een perfecte balans kunt vinden tussen werk en privéleven, waardoor je optimaal kunt genieten van beide werelden. Daarnaast kun je als freelancer bij een kinderopvang verschillende ervaringen opdoen door in diverse instellingen in Utrecht te werken, wat je professionele leven verrijkt en je een brede kennis en ervaring binnen de kinderopvangsector oplevert. Het werken in verschillende omgevingen rondom de Domstad als  <a href="https://berenvriendjes.nl/zzp-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er</a> geeft je ook de mogelijkheid om een groot netwerk op te bouwen, wat je toekomstige carrière ten goede komt.
    </>
  ),
  TEXT_PAGE_02_DESCRIPTION3: (
    <>
      Heb je nog vragen of wil je meer weten over de mogelijkheden om freelancer bij een kinderopvang te worden? Neem vandaag nog <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op en ontdek hoe jij als freelancer bij een kinderopvang in Utrecht en omstreken aan de slag kunt gaan en het verschil kunt maken in het leven van kinderen! Bekijk ook zeker de <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> op ons platform om alvast een beeld te krijgen van de mogelijkheden.
    </>
  ),


  
 
  TEXT_PAGE_03_DESCRIPTION1: (
    <>
      Een zzp’er worden bij een kinderopvang in Utrecht is mogelijk via ons. Als zzp’er werk je op freelance basis en bied je je diensten aan verschillende kinderopvangorganisaties. Dit betekent dat je de vrijheid hebt om je eigen uren te bepalen en je eigen opdrachten te kiezen. Wij bij Berenvriendjes helpen je graag verder om jouw carrière in de kinderopvang te starten of uit te breiden. 
    </>
  ),
  TEXT_PAGE_03_DESCRIPTION2: (
    <>
    Als zzp’er bij een kinderopvang in Utrecht en omstreken heb je de kans om je passie voor het werken met kinderen te combineren met de voordelen van zelfstandig ondernemerschap. Werken als zzp’er bij een kinderopvang biedt diverse voordelen. Allereerst kun je als <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a> kiezen voor opdrachten die perfect bij jouw specialisaties en interesses passen. Dit kan variëren van het verzorgen van baby’s en peuters tot het begeleiden van oudere kinderen bij educatieve activiteiten. Werken als zzp’er in de kinderopvang betekent ook dat je kunt genieten van een gevarieerde en flexibele werkomgeving, omdat je de mogelijkheid hebt om bij verschillende kinderopvangcentra rondom de Domstad en gezinnen aan de slag te gaan. Dit zorgt voor een dynamische en uitdagende werkervaring.
    </>
  ),
  TEXT_PAGE_03_DESCRIPTION3: (
    <>
      Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op voor meer informatie over de <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-utrecht" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>beschikbare vacatures</a> en mogelijkheden. Onze expertise en uitgebreide netwerk zorgen ervoor dat jij snel en effectief aan de slag kunt als zzp’er in de kinderopvang. Ontdek hoe jij als zzp’er via <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a> een waardevolle bijdrage kunt leveren aan de zorg en ontwikkeling van kinderen.
    </>
  ),

  
  TEXT_PAGE_04_DESCRIPTION1: (
    <>
      Kinderopvang vacatures in de buurt van Den Haag vind je gemakkelijk via ons platform. Ook bieden wij bij Berenvriendjes een uitgebreid overzicht van beschikbare functies in verschillende kinderopvangcentrums door de hele stad en daarbuiten. Wij helpen je bij het vinden van de juiste vacature voor een kinderdagverblijf die aansluit bij jouw wensen en kwalificaties. 
    </>
  ),
  TEXT_PAGE_04_DESCRIPTION2: (
    <>
     Als je op zoek bent naar de leukste vacatures om te <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> bij een kinderopvang, dan ben je bij ons aan het juiste adres. Onze expertise en uitgebreide netwerk zorgen ervoor dat jij snel en effectief aan de slag kunt als zzp’er in de kinderopvang. Ontdek hoe jij als zzp’er via <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> bij een kinderopvang, dan ben je bij ons aan het juiste adres. Ons platform biedt een breed scala aan vacatures per functie bij een kinderdagverblijf, variërend van pedagogisch medewerkers tot groepsleiders, zowel in loondienst als voor zzp'ers. Werken bij een kinderopvang betekent dat je dagelijks een positieve impact kunt maken op het leven van kinderen, terwijl je werkt in een dynamische, flexibele en ondersteunende omgeving. Of je nu een doorgewinterde professional bent of net je carrière begint als freelancer, wij hebben de juiste vacatures voor jou om te werken in een kinderdagverblijf in de Hofstad en omstreken.
    </>
  ),
  TEXT_PAGE_04_DESCRIPTION3: (
    <>
     Vind de juiste kinderdagverblijf vacature via ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a>. Wij bieden persoonlijke begeleiding en ondersteuning <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>via ons platform</a> om ervoor te zorgen dat je in de juiste kinderopvang gaat werken. Bekijk de actuele vacatures op ons platform en solliciteer direct om jouw volgende stap in de kinderopvang te zetten. Heb je vragen of wil je meer informatie? Neem dan <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op. Wij staan klaar om je te helpen bij elke stap van het proces.  
    </>
  ),



 
  TEXT_PAGE_05_DESCRIPTION1: (
    <>
     Om freelancer bij een kinderopvang in Den Haag en omstreken te worden kun je je aanmelden bij Berenvriendjes en direct aan de slag gaan. Als freelancer bij een kinderopvang heb je de mogelijkheid om flexibel te werken, je eigen opdrachten te kiezen en je passie voor kinderen volledig tot zijn recht te laten komen. Op ons platform vind je een breed aanbod aan mogelijkheden die perfect aansluiten bij jouw wensen en kwaliteiten.
    </>
  ),
  TEXT_PAGE_05_DESCRIPTION2: (
    <>
      Werken als freelancer bij een kinderopvang biedt jou de kans om verschillende functies en omgevingen uit te proberen. Dit is een geweldige manier om waardevolle ervaring op te doen en te ontdekken welke aspecten van de kinderopvang jou het meest aanspreken. Als je een passie hebt voor alles wat met <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> te maken heeft, dan is werken bij een kinderopvang in de buurt van Den Haag echt iets voor jou. Via ons platform kun je verschillende <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> vinden, banen proberen en een diverse werkervaring opbouwen, wat je zowel professioneel als persoonlijk verrijkt. Dat is nou net hetgeen wat je wil als freelancer in de Hofstad.
    </>
  ),
  TEXT_PAGE_05_DESCRIPTION3: (
    <>
     Meld je vandaag nog aan op ons platform. Hier vind je de meest actuele en interessante opdrachten in regio Den Haag die passen bij jouw vaardigheden en interesses. Heb je vragen of wil je meer informatie? Neem dan gerust <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op via e-mail. Wij als <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> staan klaar om je te helpen en te begeleiden bij elke stap van je freelancecarrière in de kinderopvang. 
    </>
  ),



 
  TEXT_PAGE_06_DESCRIPTION1: (
    <>
    Berenvriendjes biedt als uitzendbureau een breed scala aan medewerkers in de kinderopvang in Den Haag en omstreken. Of je nu op zoek bent naar een medewerker die graag in loondienst komt of een flexibele freelancer, ons uitzendbureau heeft de perfecte match voor jou. Met ons uitgebreide netwerk van pedagogische medewerkers die werken in verschillende kinderopvangcentra in regio Den Haag, kunnen we je helpen om de ideale kandidaat te vinden die aansluit op jouw wensen.
    </>
  ),
  TEXT_PAGE_06_DESCRIPTION2: (
    <>
      De zoektocht naar een medewerker in de kinderopvang kan lastig zijn, maar wij proberen als uitzendbureau iedereen te helpen hiermee. Wij hebben vele kandidaten beschikbaar in en rondom de Hofstad. Dit zijn kandidaten die zowel in loondienst of als <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp'er</a> zzp’er willen werken. Of je nu op zoek bent naar een ervaren professional of iemand met wat minder ervaring, wij hebben een geschikte kandidaat voor jou. Ons platform biedt de mogelijkheid om snel en eenvoudig de beste match in de kinderopvang te vinden. Dit resulteert erin dat je binnen een korte tijd het personeelstekort op jouw kinderdagverblijf kunt oplossen door iemand in te schakelen die wilt <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a> én woonachtig is in de omgeving. We hebben al vele kinderopvangorganisaties geholpen om een medewerker in de kinderopvang te vinden, en we staan klaar om ook jouw organisatie te helpen.
    </>
  ),
  TEXT_PAGE_06_DESCRIPTION3: (
    <>
     Ons platform is gebruiksvriendelijk en efficiënt, waardoor je snel toegang hebt om de kandidaten voor jouw <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>nieuwste vacatures</a> te bekijken. Neem de stap en ontdek de mogelijkheden die wij te bieden hebben. Samen zorgen we ervoor dat jouw kinderopvang rondom Den Haag wordt voorzien van een behulpzame en prettige medewerker. Neem dus snel    <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>op.
    </>
  ),


  TEXT_PAGE_07_DESCRIPTION1: (
    <>
     Als zzp’er in de kinderopvang in Den Haag en omstreken kun je een flexibele carrière tegemoet gaan. Bij Berenvriendjes kun je aan de slag in een omgeving waar jouw passie voor kinderen volledig tot zijn recht komt. Of je nu al jaren ervaring hebt of net begint, wij helpen je om jouw ideale opdrachten te vinden en te profiteren van de vrijheid die het zzp’er zijn biedt.
    </>
  ),
  TEXT_PAGE_07_DESCRIPTION2: (
    <>
     Er zijn talloze voordelen aan het werken als zzp’er in de kinderopvang in de regio Den Haag. Je kunt je eigen werktijden bepalen en kiezen welke opdrachten het beste bij jou passen. Dit geeft je de mogelijkheid om een goede balans te vinden tussen werk en privéleven. Zo kun jij je concentreren op wat echt belangrijk is: het bieden van de beste zorg als zzp’er en het    <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a>  in een kinderopvang.
    </>
  ),
  TEXT_PAGE_07_DESCRIPTION3: (
    <>
     Meld je vandaag nog aan en ontdek hoe wij je kunnen helpen met onze leuke    <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures.</a>  Profiteer van de vele mogelijkheden die deze vorm van werken biedt. Onze expertise, ondersteuning en    <a href="https://www.berenvriendjes.nl/about" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werkwijze</a> zorgen ervoor dat jij snel en moeiteloos aan de slag kunt in Den Haag of omstreken. 
    </>
  ),


  TEXT_PAGE_08_DESCRIPTION1: (
    <>
    Werken met kinderen in regio Den Haag is een ongelooflijk leuke en bevredigende ervaring. Bij Berenvriendjes kun je jouw passie voor het begeleiden en ondersteunen van kinderen tot uiting laten komen in een dynamische en stimulerende omgeving. Den Haag, met zijn diverse en levendige gemeenschap, biedt een geweldige omgeving om te werken met kinderen, waarbij elke dag nieuwe uitdagingen en vreugde brengt.
    </>
  ),
  TEXT_PAGE_08_DESCRIPTION2: (
    <>
     Werken met kinderen is niet alleen leuk, maar ook van groot belang. Of je nu kiest voor een positie in loondienst of een    <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancepositie,</a> het werken met kinderen biedt een unieke kans om een positieve invloed uit te oefenen op de jongste generaties. Als professional in de kinderopvang ben je een rolmodel voor de kinderen en speel je een belangrijke rol in hun ontwikkeling. Ouders vertrouwen erop dat jij helpt bij de opvoeding en de motorische, cognitieve en sociale vaardigheden van hun kinderen bevordert. Het ontwikkelen van een veilige en stimulerende omgeving hier in regio Den Haag is belangrijk voor hun groei en welzijn. Daarnaast leer je kinderen belangrijke levensvaardigheden, zoals samenwerken, delen en communiceren. Jouw inzet draagt bij aan het leggen van een sterke basis voor hun toekomst.
    </>
  ),
  TEXT_PAGE_08_DESCRIPTION3: (
    <>
     Ben je enthousiast om te werken met kinderen in regio Den Haag en ben je benieuwd naar de    <a href="https://www.berenvriendjes.nl/kinderopvang-vacatures-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> die wij bieden? Neem dan    <a href="https://www.berenvriendjes.nl/werken-met-kinderen-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}> contact met ons</a> op voor meer informatie over de mogelijkheden. Bij ons    <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-den-haag" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> kun je rekenen op een ondersteunende werkomgeving waar jouw werk echt gewaardeerd wordt. We staan klaar om je te helpen bij elke stap op weg naar jouw nieuwe carrière!
    </>
  ),  


  TEXT_PAGE_09_DESCRIPTION1: (
    <>
      Vacatures voor een kinderopvang in regio Amsterdam vind je eenvoudig via ons platform. Wij bij Berenvriendjes bieden een breed scala aan vacatures voor verschillende kinderopvangcentrums. Ons doel is om jou te helpen een kinderopvang te vinden in Amsterdam en omstreken waar je jouw passie voor het werken met kinderen kunt inzetten en bijdragen aan hun ontwikkeling en welzijn.
    </>
  ),
  TEXT_PAGE_09_DESCRIPTION2: (
    <>
     Reageren op een vacature voor een kinderdagverblijf in de omgeving van Amsterdam is nog nooit zo eenvoudig geweest. Via ons platform kun je snel en gemakkelijk solliciteren op de posities die bij jou passen. Of je nu als <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a>  wilt werken of op zoek bent naar een flexibel contract, wij bieden beide mogelijkheden. Werken in loondienst of als <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp'er</a> biedt je de flexibiliteit om je eigen werktijden te bepalen en diverse ervaringen op te doen bij verschillende kinderopvangcentrums. Dit kan je professionele netwerk uitbreiden en je vaardigheden verder ontwikkelen. Aan de andere kant biedt een aanstelling in loondienst je meer stabiliteit en zekerheid, met een vast uurloon en vaak ook secundaire arbeidsvoorwaarden, zoals vakantiedagen en pensioenopbouw. Beide opties hebben hun eigen voordelen en via ons platform kun je de keuze maken voor de vacature van een kinderdagverblijf die het beste bij jouw situatie en voorkeuren past.
    </>
  ),
  TEXT_PAGE_09_DESCRIPTION3: (
    <>
     Meld je aan op <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a> en bekijk de vele vacatures voor een kinderdagverblijf die wij te bieden hebben in regio Amsterdam. Ons platform is gebruiksvriendelijk en helpt je snel de kinderopvang te vinden die bij jou past en waar je wil werken. Heb je vragen of wil je meer informatie? Neem dan <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op via onder andere e-mail. Ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> staat klaar om je te ondersteunen en te begeleiden in je zoektocht naar de perfecte vacature in een kinderdagverblijf. 
    </>
  ),  


  TEXT_PAGE_10_DESCRIPTION1: (
    <>
      Een freelancer in de kinderopvang in regio Amsterdam worden kan bij Berenvriendjes, want wij hebben vele opdrachten beschikbaar voor freelancers. De hoofdstad biedt talloze mogelijkheden voor professionals die graag met kinderen aan de slag en hun talenten willen inzetten om een verschil te maken in hun leven. Ons platform is ontworpen om jou te helpen de perfecte freelance positie te vinden in de kinderopvangsector.
    </>
  ),
  TEXT_PAGE_10_DESCRIPTION2: (
    <>
     In een grote stad zoals Amsterdam is de vraag naar werknemers bij een kinderopvang hoog, wat betekent dat er veel kansen zijn voor jou als freelancer in de kinderopvang. Amsterdam en omgeving heeft een groot aantal kinderopvangcentrums, variërend van kleine buurtcentra tot grote, professionele instellingen. Deze diversiteit zorgt ervoor dat je altijd een plek kunt vinden die bij jou past. Via ons platform bieden wij vele <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> aan die speciaal gericht zijn op freelancers. Of je nu liever fulltime werkt of op zoek bent naar flexibele uren, er is altijd een geschikte positie te vinden via ons <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau.</a> Door de constante vraag naar gekwalificeerde werknemers in de kinderopvang, zijn er altijd nieuwe kansen om aan de slag te gaan.
    </>
  ),
  TEXT_PAGE_10_DESCRIPTION3: (
    <>
     Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op voor meer informatie over ons platform en de beschikbare functies in en rondom Amsterdam. Wij helpen je graag om de juiste positie te vinden die aansluit bij jouw wensen en kwalificaties. Ons team staat klaar om al je vragen te beantwoorden en je te begeleiden bij elke stap van het proces. 
    </>
  ),  


  TEXT_PAGE_11_DESCRIPTION1: (
    <>
      Als uitzendbureau voor een kinderopvang in de buurt van Amsterdam helpen wij jou de perfecte kandidaat vinden voor een opening bij jouw kinderopvang. Via Berenvriendjes kun je eenvoudig een leuke en passende baan in de kinderopvangsector aanbieden. Ons uitzendbureau biedt een breed scala aan kandidaten die aansluiten bij jouw wensen en kwalificaties.
    </>
  ),
  TEXT_PAGE_11_DESCRIPTION2: (
    <>
      Een uitzendbureau inschakelen biedt jou vele voordelen voor je kinderopvang. Allereerst nemen wij de volledige leiding in het sollicitatieproces, zodat jij je kunt concentreren op wat echt belangrijk is: het zorgen voor en begeleiden van kinderen. Wij luisteren naar jouw wensen en behoeften en verwerken deze in een of meerdere <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a>. Daarnaast matchen we de juiste kandidaten met de opdrachtgevers. Dit betekent dat je altijd zeker bent van een kandidaat die perfect bij jou opening past. Je hebt de keuze om een <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp'er</a> of iemand die graag in loondienst werkt in te zetten. Met ons uitgebreide netwerk kunnen we snel en eenvoudig een geschikte kandidaat koppelen. Onze expertise en ervaring zorgen ervoor dat je zonder stress en met vertrouwen kunt bouwen op ons. Door gebruik te maken van ons uitzendbureau bespaar je tijd en moeite, en vergroot je je kansen op het vinden van een ideale kandidaat in de kinderopvang ergens in of rondom de hoofdstad.
    </>
  ),
  TEXT_PAGE_11_DESCRIPTION3: (
    <>
     Bij ons geniet je van de vele voordelen die het inschakelen van een uitzendbureau biedt. Wij helpen je bij elke stap van het proces en zorgen ervoor dat je snel en gemakkelijk aan een kandidaat vindt in de kinderopvangsector. Heb je vragen of wil je meer informatie? Neem dan gerust <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>. Ontdek hoe wij jou kunnen helpen om de perfecte medewerker in de kinderopvang te vinden in de regio Amsterdam en meld je nu aan om te genieten van alle voordelen die wij te bieden hebben.
    </>
  ),  

 
  TEXT_PAGE_12_DESCRIPTION1: (
    <>
      Als zzp’er bij een kinderopvang in Amsterdam en omgeving heb je de flexibiliteit om eenvoudig van job te wisselen als het niet bevalt. Dit biedt een enorme vrijheid en de mogelijkheid om verschillende werkplekken en werkomgevingen te ervaren. Zo kun je via Berenvriendjes ontdekken wat het beste bij jou past en waar je je talenten het beste kunt inzetten. 
    </>
  ),
  TEXT_PAGE_12_DESCRIPTION2: (
    <>
      Werken als zzp’er in de kinderopvang in onze hoofdstad biedt vele voordelen. Ten eerste heb je als zzp’er de vrijheid om je eigen werktijden en opdrachten te kiezen, wat een ideale balans tussen werk en privéleven mogelijk maakt. In een stad als Amsterdam, waar de vraag naar een kinderopvang groot is, zijn er altijd veel mogelijkheden voor <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancers</a>. Dit betekent dat je kunt genieten van een stabiele stroom aan opdrachten en de flexibiliteit hebt om te kiezen waar en wanneer je werkt. Daarnaast geeft het werken als zzp’er in de kinderopvang je de kans om een breed netwerk op te bouwen en waardevolle ervaringen op te doen in verschillende kinderopvangcentra. Dit kan je carrière via ons <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a>verder versterken en nieuwe deuren openen in regio Amsterdam. 
    </>
  ),
  TEXT_PAGE_12_DESCRIPTION3: (
    <>
     Meld je aan bij ons platform en ontdek de vele <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> die wij te bieden hebben in de regio van Amsterdam. Wij staan klaar om je te helpen bij elke stap van het proces en zorgen ervoor dat je snel aan de slag kunt in de kinderopvangsector. Neem vandaag nog  <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op en start jouw carrière als zzp’er in de kinderopvang!
    </>
  ), 


  TEXT_PAGE_13_DESCRIPTION1: (
    <>
      Vacatures voor een kinderopvang in regio Rotterdam vind je eenvoudig via ons. Wij bieden een breed scala aan mogelijkheden in de regio Rotterdam, zodat je altijd een passende functie kunt vinden die aansluit bij jouw wensen en kwalificaties. Door ons uitgebreide netwerk bij Berenvriendjes in de kinderopvangsector kunnen wij je begeleiden bij elke stap van het proces.
    </>
  ),
  TEXT_PAGE_13_DESCRIPTION2: (
    <>
      Werken in een kinderopvang in en rondom de havenstad betekent dat je een positieve bijdrage levert aan de ontwikkeling en het welzijn van kinderen. Ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> biedt een breed aanbod aan vacatures, van pedagogisch medewerkers tot leidinggevende functies bij een kinderdagverblijf. Zowel als <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancer</a> als in loondienst. Met onze kennis en expertise van een kinderopvang helpen wij je om de juiste vacature te vinden en om uiteindelijk in een kinderdagverblijf te werken. Wij begrijpen dat elke professional unieke vaardigheden en voorkeuren heeft, en daarom bieden wij maatwerkoplossingen om ervoor te zorgen dat je de perfecte match vindt. Door onze begeleiding en ondersteuning kun je met vertrouwen solliciteren op een vacature binnen een kinderdagverblijf in Rotterdam en omstreken en uiteindelijk <a href="https://berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}> werken met kinderen.</a>
    </>
  ),
  TEXT_PAGE_13_DESCRIPTION3: (
    <>
    <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>ons platform</a>is ontworpen om jou te helpen bij het vinden van de ideale vacature in de kinderopvangsector. Of je nu een doorgewinterde professional bent, een <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er</a>  bent of net je carrière begint, wij staan klaar om je te ondersteunen en te begeleiden bij elke stap. Meld je vandaag nog aan door <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>op te nemen en ontdek de vele vacatures om te werken bij een kinderdagverblijf. 
    </>
  ),  



  TEXT_PAGE_14_DESCRIPTION1: (
    <>
      Freelancer zijn bij een kinderopvang in regio Rotterdam biedt jou de flexibiliteit en vrijheid om eenvoudig aan een baan te komen via Berenvriendjes. Als freelancer kun je zelf bepalen waar en wanneer je werkt, wat een ideale balans creëert tussen werk en privéleven. In een grote stad als Rotterdam zijn er tal van mogelijkheden en vacatures in de kinderopvangsector.
    </>
  ),
  TEXT_PAGE_14_DESCRIPTION2: (
    <>
      De mogelijkheden voor een freelancer in de kinderopvang zijn eindeloos in Rotterdam en omstreken. Door de vele vacatures heb je als <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er </a> zelf de keuze om te bepalen waar je wilt werken. Het voordeel van een grote stad als Rotterdam is het brede aanbod aan kinderopvangcentra, variërend van kleinschalige opvang tot grotere instellingen. Hierdoor kun je eenvoudig van werkplek wisselen als je behoefte hebt aan een nieuwe uitdaging of als een bepaalde plek niet aan je verwachtingen voldoet. Deze flexibiliteit van ons <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> stelt je in staat om diverse ervaringen op te doen en je professionele netwerk uit te breiden, wat je carrière ten goede komt.
    </>
  ),
  TEXT_PAGE_14_DESCRIPTION3: (
    <>
      Door je aan te melden bij ons, krijg je toegang tot een breed scala aan <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-amsterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a>en de mogelijkheid om snel en eenvoudig aan de slag te gaan. Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op via e-mail om je aan te melden om met <a href="https://www.berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>kinderen te werken</a>in de havenstad. Ontdek hoe wij jou kunnen helpen om een succesvolle carrière als freelancer in de kinderopvang te starten. 
    </>
  ),  


  TEXT_PAGE_15_DESCRIPTION1: (
    <>
      Als uitzendbureau voor een kinderopvang in regio Rotterdam helpen wij bij Berenvriendjes mensen aan een baan in de kinderopvangsector. Ons doel is om zowel kinderopvangcentrums als werkzoekenden te ondersteunen door de juiste match te vinden. Ons uitzendbureau heeft de expertise en het netwerk om jou te helpen.
    </>
  ),
  TEXT_PAGE_15_DESCRIPTION2: (
    <>
      Als uitzendbureau in de kinderopvang bieden wij opvangcentrums in en rondom de havenstad onze expertise aan door gekwalificeerd personeel aan te leveren. Wij begrijpen de unieke behoeften van kinderopvangcentrums en zorgen ervoor dat de mensen die je via ons platform kan aannemen, perfect aansluiten bij jouw behoeften. Of het nu gaat om <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancers</a> of langdurige samenwerkingen, wij leveren professionals die enthousiast en bekwaam zijn. Wanneer je op zoek bent naar mensen die willen <a href="https://www.berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a>in Rotterdam en omstreken, dan gaan wij graag voor je op zoek. Via ons platform kun je jouw <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacature in de kinderopvang</a>plaatsen. Het maakt niet uit of dit voor een fulltimepositie of een korte opdracht is, wij vinden een geschikte kandidaat.
    </>
  ),
  TEXT_PAGE_15_DESCRIPTION3: (
    <>
      Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a>   op om meer te weten te komen. Ons team staat klaar om al je vragen te beantwoorden en je te begeleiden in je zoektocht naar de perfecte baan ergens in Rotterdam. Samen kunnen we de juiste stappen zetten om jouw doelen te bereiken. Ontdek vandaag nog hoe wij jou kunnen helpen als uitzendbureau in de kinderopvangsector.
    </>
  ), 


  TEXT_PAGE_16_DESCRIPTION1: (
    <>
      Werken met kinderen in Rotterdam en omgeving geeft je een ongekende energie en voldoening. Elke dag biedt nieuwe avonturen en uitdagingen, en het werken met kinderen kan jouw leven verrijken op manieren die je je misschien niet eens kunt voorstellen. Via Berenvriendjes kom je op vele opties terecht om te werken.
    </>
  ),
  TEXT_PAGE_16_DESCRIPTION2: (
    <>
     Wanneer je kiest voor werken met kinderen, ontdek je al snel hoeveel je van hen kunt leren. Kinderen bieden een frisse blik op de wereld en door met hen te werken, krijg je een dieper begrip van hun behoeften, gedragingen en manieren van denken. Rotterdam, met zijn rijke verscheidenheid aan culturen en etniciteiten, geeft je de unieke kans om te leren omgaan met verschillende achtergronden en opvoedstijlen. Dit verrijkt niet alleen je professionele vaardigheden, maar helpt je ook om een breder perspectief op de wereld te ontwikkelen, wat je persoonlijke groei en begrip van anderen ten goede komt. Via ons  <a href="https://www.berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}> uitzendbureau</a> kun je een kinderopvang vinden door heel de regio Rotterdam. Hiervoor staan diverse  <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>vacatures</a> open op ons platform.
    </>
  ),
  TEXT_PAGE_16_DESCRIPTION3: (
    <>
      Ben je klaar om te starten met werken met kinderen in regio Rotterdam? Neem dan <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op om de perfecte job te vinden die bij jou past. Of je nu op zoek bent naar een baan in loondienst of als <a href="https://www.berenvriendjes.nl/zzp-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>zzp’er</a> aan de slag te gaan, wij staan klaar om je te ondersteunen. 
    </>
  ), 



  TEXT_PAGE_17_DESCRIPTION1: (
    <>
      Wordt zzp’er in de kinderopvang in Rotterdam of omgeving en ontdek de vele mogelijkheden die deze stad te bieden heeft via Berenvriendjes. Er is een enorme vraag naar gekwalificeerde kinderopvangprofessionals, en als zzp’er kun je genieten van de vrijheid en flexibiliteit om je eigen opdrachten te kiezen. Of je nu net bent afgestudeerd of op zoek bent naar een nieuwe uitdaging, de kinderopvangsector biedt volop kansen voor jou.
    </>
  ),
  TEXT_PAGE_17_DESCRIPTION2: (
    <>
      Zzp’er worden in de kinderopvang brengt talloze voordelen met zich mee. Ten eerste heb je de vrijheid om je eigen werktijden en opdrachten te bepalen. Als je net bent afgestudeerd of op zoek bent naar een carrièreswitch, biedt de kinderopvangbranche in regio Rotterdam veel werkgelegenheid. Je kunt verschillende kinderopvangcentra verkennen en werken op locaties die bij jou passen, wat je professionele groei en netwerk aanzienlijk kan vergroten. Daarnaast geeft het <a href="https://www.berenvriendjes.nl/freelancer-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>freelancen</a> zijn je de mogelijkheid om waardevolle ervaring op te doen in diverse omgevingen. Je ontwikkelt niet alleen je vaardigheden, maar bouwt ook aan een veelzijdige carrière. Het <a href="https://www.berenvriendjes.nl/werken-met-kinderen-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>werken met kinderen</a>werken met kinderen in een grote stad als Rotterdam stelt je bloot aan een breed scala aan culturele en sociale dynamieken. Dit komt je professionele en persoonlijke ontwikkeling ten goede. Geniet van de voordelen van flexibiliteit, diversiteit en de mogelijkheid om jezelf voortdurend uit te dagen en te groeien, en bekijk <a href="https://www.berenvriendjes.nl/vacatures-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>onze vacatures.</a>
    </>
  ),
  TEXT_PAGE_17_DESCRIPTION3: (
    <>
      Neem <a href="https://www.berenvriendjes.nl/contact" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>contact met ons</a> op om de leukste en meest geschikte opdrachten te vinden in regio Rotterdam. Ons <a href="https://berenvriendjes.nl/uitzendbureau-kinderopvang-rotterdam" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>uitzendbureau</a> staat klaar om je te helpen bij elke stap van je zzp-reis bij een kinderopvang. <a href="https://www.berenvriendjes.nl" target="_blank" rel="noopener noreferrer" style={{color:'blue', fontSize:14, border:1, borderBottomWidth:1, borderColor:'blue'}}>Ons platform</a> biedt een breed scala aan mogelijkheden en wij zorgen ervoor dat je snel en gemakkelijk aan de slag kunt. 
    </>
  ), 
  
};
